import httpClient from "@/httpClient"

const getTags = () => httpClient.get('{currentCompanySlug}/tags')

const createTag = (payload) => httpClient.post('{currentCompanySlug}/tags', payload)

const attachTag = (payload) => httpClient.post(`{currentCompanySlug}/tags/${payload.tagId}/attach`, payload)

const detachTag = (payload) => httpClient({
  method: 'DELETE',
  url: `{currentCompanySlug}/tags/${payload.tagId}/detach`,
  data: payload,
})

const deleteTag = (tagId) => httpClient.delete(
  `{currentCompanySlug}/tags/${tagId}`,
  {
    headers: {
      notification: 'silent',
    },
  }
)

const renameTag = (tagId, data) => httpClient.put(
  `{currentCompanySlug}/tags/${tagId}`,
  data,
)

const getTag = (tagId) => httpClient.get(`{currentCompanySlug}/tags/${tagId}`)

export default {
  getTags,
  createTag,
  attachTag,
  detachTag,
  deleteTag,
  renameTag,
  getTag,
}
