import CUSTOM_FIELDS_TYPES from '@/constants/enums/CUSTOM_FIELDS_TYPES'
import formatActivityStream from '@/js/filters/formatActivityStream'
import CUSTOM_FIELDS_TABLE_TYPES from "@/constants/enums/CUSTOM_FIELDS_TABLE_TYPES"

export default {
  getRecordStatuses: ({ statuses }) => statuses
    && statuses.map((status) => ({
      label: status.name,
      value: status.slug,
      color: status.color,
      description: status?.description,
      id: status.id,
    })),

  getFilterList: ({ filterList }) => filterList && Object.entries(filterList),

  getPaginationPage: ({ pagination }) => pagination && Object.values(pagination).map((currentPage) => currentPage)[0],

  getFilterSetting: ({ filtersSettings }) => Object.entries(filtersSettings?.data?.lastRequestQueryLog?.data || {})
    .reduce((filters, [key, filter]) => (
      !key.includes('filters') ? { ...filters } : { ...filters, [key]: filter }
    ), {}),

  getFilterStatusSetting: ({ filtersSettings }) => filtersSettings?.data?.lastRequestQueryLog?.data?.statusesIn || '',

  getStatusOptions: ({ statuses }) => statuses?.map((status) => (
    { label: status.name, value: status.slug, description: status?.description, closed: status.closed })) || [],

  getSectionsRecord: ({ sections }) => {
    return (sections || []).reduce((acc, section) => {
      acc[section.slug] = section
      return acc
    }, {}) || {}
  },

  getRecordListPreviewConfigFields: (
    { customFieldsConfiguration, sections },
  ) => {
    const filteredConfiguration = customFieldsConfiguration
      .filter((item) => {
        const isShowField = item.showInCreateQuoteForm && item.active
        if (item.type === CUSTOM_FIELDS_TYPES.object) {
          return isShowField && item.objectConfiguration.some((field) => field.active)
        }
        return isShowField
      }).map((fieldConfig) => {
        return {
          ...fieldConfig,
          section: sections.find((section) => section.slug === fieldConfig.sectionSlug),
        }
      })
    return Object.values(filteredConfiguration.reduce((acc, field) => {
      if (acc[field.sectionSlug]) {
        return {
          ...acc,
          [field.sectionSlug]: [...acc[field.sectionSlug], field],
        }
      }
      return {
        ...acc,
        [field.sectionSlug]: [field],
      }
    }, {}))
      .sort((a, b) => a[0]?.section?.position - b[0]?.section?.position)
      .map((arrayOfConfiguration) => [...arrayOfConfiguration].sort((a, b) => a.position - b.position))
      .flat()
  },

  recordCustomFieldsConfigurationOptions: ({ customFieldsConfiguration }) => customFieldsConfiguration
    ?.filter((customField) => ![
      CUSTOM_FIELDS_TYPES.object,
      CUSTOM_FIELDS_TYPES.task,
    ].includes(customField.type) && customField.active)
    ?.map((customField) => ({
      value: customField.slug,
      label: customField.name,
      type: customField.type,
    })) || [],

  recordObjectFieldsOptions: ({ customFieldsConfiguration }) => customFieldsConfiguration
    ?.filter((customField) => [
      CUSTOM_FIELDS_TYPES.object,
      CUSTOM_FIELDS_TYPES.task,
    ].includes(customField.type) && customField.active)
    ?.map((customField) => ({
      value: customField.slug,
      label: customField.name,
      options: customField.objectConfiguration
        .filter((field) => field.active)
        .map((field) => ({
          value: field.name,
          label: field.title,
          type: field.type,
          options: field.options,
        })),
    })) || [],

  getFormattedRecords: (
    {
      customFieldsConfiguration,
      records,
    },
    _,
    rootState,
  ) => records.map((record) => {
    const disabledFields = (
      record.meta?.customFieldConfiguration instanceof Array
        ? record.meta.customFieldConfiguration
        : []
    )
      ?.filter((field) => field.disabled)
      ?.map((field) => field.slug) || []
    return {
      ...record,
      customFields: record.customFields.map((customField) => {
        const fondedCustomField = customFieldsConfiguration
          .find((configurationField) => configurationField.slug === customField.slug)
        return {
          ...customField,
          ...(fondedCustomField || {}),
          disabled: disabledFields.includes(customField.slug),
        }
      }),
      recordType: rootState.recordType.recordsType
        ?.find(({ id }) => id === record.recordTypeId) || null,
    }
  }),

  getFormattedRecord: (
    { record, customFieldsConfiguration, sections },
    { getRecordTypeSections },
    rootState,
    rootGetters,
  ) => {
    const recordType = rootState.recordType.recordsType
      ?.find(({ id }) => id === record.recordTypeId) || null
    const disabledFields = (
      record.meta?.customFieldConfiguration instanceof Array
        ? record.meta?.customFieldConfiguration
        : []
    )
      ?.filter((field) => field.disabled)
      ?.map((field) => field.slug) || []

    return {
      ...record,
      collaborators: record.collaborators || [],
      recordType: recordType,
      customFields: record.customFields.map((customField) => {
        const fondedCustomField = customFieldsConfiguration
          .find((configurationField) => configurationField?.slug === customField.slug)

        if (!fondedCustomField) {
          return null
        }

        if ([CUSTOM_FIELDS_TYPES.object, CUSTOM_FIELDS_TYPES.task].includes(fondedCustomField.type)) {
          return {
            ...fondedCustomField,
            ...customField,
            section: sections.find((section) => fondedCustomField.sectionSlug === section.slug),
          }
        }

        if (fondedCustomField.required) {
          return {
            ...fondedCustomField,
            ...customField,
            disabled: disabledFields.includes(customField.slug),
            section: sections.find((section) => fondedCustomField.sectionSlug === section.slug),
            response: customField.response,
          }
        }

        return {
          ...fondedCustomField,
          ...customField,
          disabled: disabledFields.includes(customField.slug),
          section: sections.find((section) => fondedCustomField.sectionSlug === section.slug),
          response: customField.response,
        }
      })
        .filter(Boolean)
        .filter((field) => {
          return recordType && rootGetters['user/isRecordTypesEnabled']
            ? getRecordTypeSections.includes(field.section.slug)
            : true
        }),
    }
  },

  getRecordTypeSections: ({ record }, _, rootState) => {
    const recordType = rootState.recordType.recordsType
      ?.find(({ id }) => id === record.recordTypeId) || null

   return recordType?.sections.map(({ slug }) => slug) || []
  },

  getFormattedActivityStream: ({ activityStream }, _, __, rootGetters) => {
    const isOutlookIntegrationEnabled = rootGetters['user/isOutlookIntegrationEnabled']

    const activityStreamGroups = activityStream.map(({ uuid, posts }) => {
      return {
        uuid,
        streams: posts,
        created: posts[0].created,
        type: posts[0].type,
      }
    })

    const activityStreamGroupsWithVirtual = isOutlookIntegrationEnabled
      ? activityStreamGroups.map((activityStreamGroup) => ({
        ...activityStreamGroup,
        streams: activityStreamGroup.streams.reduce((acc, stream) => {
          if (stream.type.slug !== 'message') {
            return [
              ...acc,
              stream,
            ]
          }

          const outlookIntegrableFiles = stream.attachedFiles
            .filter(({ meta, deletedDate }) => meta.isOutlookIntegrable && !deletedDate)

          if (!outlookIntegrableFiles.length) {
            return [
              ...acc,
              stream,
            ]
          }

          const outlookIntegrableFilesIds = outlookIntegrableFiles.map(({ id }) => id)

          const streamWithFilteredAttaches = {
            ...stream,
            attachedFiles: stream.attachedFiles.filter(({ id }) => !outlookIntegrableFilesIds.includes(id)),
          }

          const hasContent = Boolean(streamWithFilteredAttaches.content)
          const hasOtherAttaches = Boolean(streamWithFilteredAttaches.attachedFiles.length)

          const streamsWithVirtual = [
            ...(hasContent || hasOtherAttaches ? streamWithFilteredAttaches : []),
            ...outlookIntegrableFiles.map((file) => ({
              ...stream,
              attachedFiles: [file],
              content: '',
              type: {
                name: "Outlook",
                slug: "outlook",
              },
            })),
          ]

          return [
            ...acc,
            ...streamsWithVirtual,
          ]
        }, []),
      }))
      : activityStreamGroups

      return Object.entries(activityStreamGroupsWithVirtual.reduce((acc, currentGroup) => {
        const dateKey = formatActivityStream(currentGroup.created)
        if (acc[dateKey]) {
          acc[dateKey].push(currentGroup)
          return acc
        }
        return {
          ...acc,
          [dateKey]: [currentGroup],
        }
      }, {})).map(([date, streamGroups]) => ({
        date,
        streamGroups,
      }))
  },

  isUserCollaborator: ({ record }, getters, { user: { currentUser } }) => {
    return Boolean(record.collaborators.find((collaborator) => collaborator.accountId === currentUser.accountId))
  },

  canEdit: (state, getters, globalState, globalGetters) => {
    return globalGetters['user/isAdmin']
      || globalGetters['user/editAllRecords']
      || globalGetters['user/editCollaboratorRecords']
  },

  getStatusesSlugs: ({ statuses }) => {
    return statuses.map((status) => status.slug)
  },

  getStatusesRecord: ({ statuses }) => {
    return statuses.reduce((acc, status) => {
      acc[status.slug] = status
      return acc
    }, {})
  },

  getRecordFilesRecord: ({ recordFiles }) => {
    return recordFiles.reduce((acc, file) => {
      acc[file.id] = file
      return acc
    }, {})
  },

  getRecordInfoSections: (_, { getFormattedRecord }, { recordType }, rootGetters) => {
    if (rootGetters['user/isRecordTypesEnabled'] && recordType.isLoading) {
      return []
    }
    const result = {}
    const objectFields = []
    const taskFields = []
    const customFields = []
    const linkFields = []
    getFormattedRecord.customFields?.forEach((field) => {
      if (field.type === CUSTOM_FIELDS_TYPES.object) {
        return objectFields.push(field)
      }
      if (field.type === CUSTOM_FIELDS_TYPES.link) {
        return linkFields.push(field)
      }
      if (field.type === CUSTOM_FIELDS_TYPES.task) {
        return taskFields.push(field)
      }

      field.disabled = false

      Object.values(getFormattedRecord.meta?.customFieldConfiguration || {}).forEach(meta => {
        if (meta.slug === field.slug) {
          field.disabled = meta.disabled
        }
      })

      customFields.push(field)
    })
    customFields.forEach((field) => {
      if (!field.section) {
        return
      }
      if (result[field.section.name]) {
        result[field.section.name].push(field)
        return
      }
      result[field.section.name] = [field]
    })
    return [
      ...Object.entries(result).map(([key, value]) => ({
        title: key,
        fields: value.map((field) => ({
          ...field,
          response: field.response,
        })).sort((a, b) => a.position - b.position),
        sectionSlug: value?.[0]?.sectionSlug || null,
        type: CUSTOM_FIELDS_TABLE_TYPES.customField,
      })),
      ...objectFields.filter((field) => {
        if (!field.objectConfiguration.some((field) => field.active)) {
          return false
        }
        return field.active
      }).map((fields) => ({
        title: fields.name,
        fields: {
          ...fields,
          objectConfiguration: [...(fields?.objectConfiguration?.length ? fields.objectConfiguration : [])]
            .sort((a, b) => a.position - b.position),
        },
        sectionSlug: fields.sectionSlug,
        type: CUSTOM_FIELDS_TABLE_TYPES.object,
      })),
      ...linkFields.map((field) => ({
        title: field.name,
        fields: field,
        type: CUSTOM_FIELDS_TABLE_TYPES.link,
        sectionSlug: field.sectionSlug,
      })),
      ...taskFields.filter((field) => {
        if (!field.objectConfiguration.some((field) => field.active)) {
          return false
        }
        return field.active
      }).map((fields) => ({
        title: fields.name,
        fields: {
          ...fields,
          objectConfiguration: [...(fields?.objectConfiguration?.length ? fields.objectConfiguration : [])]
            .sort((a, b) => a.position - b.position),
        },
        sectionSlug: fields.sectionSlug,
        type: CUSTOM_FIELDS_TABLE_TYPES.task,
      })),
    ]
      .sort((curr, next) => {
        let a = curr.fields[0]?.section?.position
        if (typeof a !== 'number') {
          a = curr.fields.section.position
        }
        let b = next.fields[0]?.section?.position
        if (typeof b !== 'number') {
          b = next.fields.section.position
        }
        return a - b
      })
  },
}
