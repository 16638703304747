import LogRocket from "logrocket"

export const logrocketInit = (userData) => {
  const isValidEnvironment = ['production', 'staging'].includes(import.meta.env.VITE_APP_ENV)
  const emailsBlacklist = [
    'bystrova22.02@gmail.com.dev',
    'bystrova22.02@gmail.com',
  ]

  if (isValidEnvironment && !emailsBlacklist.includes(userData.account?.email)) {
    LogRocket.init(import.meta.env.VITE_LOG_ROCKET_APP_ID, {
      network: {
        requestSanitizer(request) {
          const requestUrlsToSanitize = [
            '/auth/login',
            '/auth/password-reset',
            '/auth/refresh-token',
            '/auth/update-invited-user',
            '/user/get-invited-user-profile',
          ]

          for (const url of requestUrlsToSanitize) {
            if (request.url.includes(url)) {
              request.body = null
            }
          }

          const requestHeadersToSanitize = [
            'Authorization',
            'Cookie',
            'X-Auth-Token',
          ]

          for (const header of requestHeadersToSanitize) {
            if (request.headers[header]) {
              request.headers[header] = null
            }
          }

          if (request.body instanceof FormData) {
            const newBody = {}
            request.body.forEach((value, key) => {
              newBody[key] = value instanceof File ? {
                lastModified: value.lastModified,
                lastModifiedDate: value.lastModifiedDate,
                name: value.name,
                size: value.size,
                type: value.type,
              } : value
            })
            request.body = JSON.stringify(newBody)
          }

          return request
        },
        responseSanitizer(response) {
          const responseFieldsToSanitize = [
            'password',
            'password_confirm',
            'token',
          ]

          let responseBody = null

          try {
            responseBody = JSON.parse(response.body)
          } catch(e) {
            return response
          }

          if (!responseBody) {
            return response
          }

          if (responseBody?.data instanceof Array) {
            return response
          }

          if (responseBody?.data) {
            for (const field of responseFieldsToSanitize) {
              if (responseBody.data[field]) {
                delete responseBody.data[field]
              }
            }
          } else {
            for (const field of responseFieldsToSanitize) {
              if (responseBody[field]) {
                delete responseBody[field]
              }
            }
          }

          response.body = JSON.stringify(responseBody)
          return response
        },
      },
    })

    LogRocket.identify(userData.account.id, {
      firstName: userData.account?.firstName,
      lastName: userData.account?.lastName,
      email: userData.account?.email,
      externalId: userData.account?.externalId,
      isAdmin: userData.companyAccount?.isAdmin,
      isHidden: userData.companyAccount?.isHidden,
      accountId: userData.companyAccount?.accountId,
      companyId: userData.companyAccount?.companyId,
      recordAccessSlug: userData.companyAccount?.recordAccess?.slug || 'Unknown',
      financialAccessSlug: userData.companyAccount?.financialAccess?.slug || 'Unknown',
    })
  }
}
